<template>
	<div :class="containerClass" @click="onWrapperClick">
		<div ref="sidebar" :class="sidebarClass" @click="onSidebarClick" @mouseenter="onSidebarMouseEnter" @mouseleave="onSidebarMouseLeave">
			<div class="sidebar-logo">
				<a href="/#/">
					<!--img alt="logo" src="assets/layout/images/logo-slim.png" /-->
					<span class="app-name">OTTHONÁPOLÓ {{ node_name }}</span>
				</a>
				<button class="p-link sidebar-anchor" title="Toggle Menu" @click="onToggleMenuClick"> </button>
			</div>

			<div class="layout-menu-container">
				<AppMenu :model="menu" :layoutMode="layoutMode" :sidebarActive="sidebarActive" :active="menuActive" :mobileMenuActive="mobileMenuActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
			</div>
		</div>

		<div class="layout-main" :class="(node_name == 'TESZT') ? 'l-teszt-layout-main' : ''">
			<AppTopBar :layoutMode="layoutMode" :activeTopbarItem="activeTopbarItem" :topbarMenuActive="topbarMenuActive" @menu-button-click="onMenuButtonClick"
						@topbar-item-click="onTopbarItemClick" @topbar-mobile-menu-button-click="onTopbarMobileMenuButtonClick"/>

			<!--AppBreadcrumb></AppBreadcrumb-->

			<div class="layout-content">
				<router-view />
			</div>

			<!--AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" :darkMenu="darkMenu" @menu-color-change="onMenuColorChange"
						:layoutColor="layoutColor" :layoutSpecialColors="layoutSpecialColors" :layoutColors="layoutColors" @layout-color-change="changeLayout"
						:theme="theme" :themes="themeColors" @theme-change="changeTheme" :compactMode="compactMode" @change-theme-style="changeThemeStyle"
						:isRTL="isRTL" @change-orientation="changeOrientation"></AppConfig-->

			<AppFooter />

			<div class="layout-main-mask" v-if="mobileMenuActive"></div>
		</div>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
//import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
//import AppBreadcrumb from './AppBreadcrumb.vue';
import EventBus from './event-bus';
import { mapGetters } from 'vuex'

export default {
	props: {
		theme: String,
		compactMode: Boolean,
		layoutColor: String
	},
    data() {
        return {
			activeTopbarItem: null,
			layoutMode: 'static',
			mobileMenuActive: null,
			topbarMenuActive: null,
			currentRoute: null,
			menuActive: false,
			darkMenu: false,
			isRTL: false,
			sidebarActive: false,
            menu : [],
            fullMenu : [
				{
					label: '',
                    items: [
                        {label: 'Felhasználó', icon: 'pi pi-fw pi-user',
                            items: [
                                {label: 'Profil', icon: 'pi pi-fw pi-user', to:'/profil'},
                                {label: 'Kilépés', icon: 'pi pi-fw pi-sign-out', to:'/logout'},
                            ]
                        }
                    ]
				},
				{
					label: 'Menu',
                    role: 'CARER',
					items: [
						{label: 'Dashboard', icon: 'pi pi-fw pi-home', to:'/kezdes'}, // más lesz az admin Dashboard és más a Carer-é
                        {label: 'Betegek', icon: 'pi pi-fw pi-users', to:'/beteg'},
                        {label: 'Rendelések', icon: 'pi pi-fw pi-shopping-cart', to:'/rendeles'},
                        {label: 'Vizit kérelmek', icon: 'pi pi-fw pi-briefcase', to:'/vizitkerelem'},
                        {label: 'Teljesítés', icon: 'pi pi-fw pi-money-bill', to:'/teljesites'},
                        {label: 'Karbantartás', icon: 'pi pi-fw pi-sitemap',
                            items: [
                            {label: 'Orvosok / lejelentők', icon: 'pi pi-fw pi-pencil', to:'/orvos'},
                            ]
                        },
					]
				},
				{
					label: 'Menu',
                    role: 'PHARMACY',
					items: [
                        {label: 'Rendelések', icon: 'pi pi-fw pi-shopping-cart', to:'/rendeles'},
					]
				},
				{
					label: 'Menu',
                    role: 'MANAGER',
					items: [
                        {label: 'Betegek', icon: 'pi pi-fw pi-users', to:'/beteg'},
					]
				},
				{
					label: 'Menu',
                    role: 'ADMIN',
					items: [
						{label: 'Dashboard', icon: 'pi pi-fw pi-home', to:'/kezdes'},
                        {label: 'Betegek', icon: 'pi pi-fw pi-users', to:'/beteg'},
                        {label: 'Rendelések', icon: 'pi pi-fw pi-shopping-cart', to:'/rendeles'},
                        {label: 'Vizit kérelmek', icon: 'pi pi-fw pi-briefcase', to:'/vizitkerelem'},
                        {label: 'Teljesítesek', icon: 'pi pi-fw pi-money-bill', to:'/teljesitesek'},
                        {label: 'Riportok', icon: 'pi pi-fw pi-chart-bar',
                         items: [
                                {label: 'Teljesítés', icon: 'pi pi-fw pi-briefcase', to:'/riport/teljesites'},
                                {label: 'Rendelés', icon: 'pi pi-fw pi-briefcase', to:'/riport/rendeles'},
                                {label: 'Új betegek', icon: 'pi pi-fw pi-briefcase', to:'/riport/ujbetegek'},
/*                                {label: 'Teljesítés', icon: 'pi pi-fw pi-briefcase', to:'/riport/teljesites'},*/
                            ]
                        },
                        {label: 'Karbantartás', icon: 'pi pi-fw pi-sitemap',
                            items: [
                            {label: 'Paraméter', icon: 'pi pi-fw pi-list', to:'/parameter'},
                            {label: 'Felhasználók', icon: 'pi pi-fw pi-users', to:'/felhasznalo'},
                            {label: 'Orvosok / lejelentők', icon: 'pi pi-fw pi-pencil', to:'/orvos'},
                            {label: 'Kórházak', icon: 'pi pi-fw pi-heart', to:'/korhaz'},
                            {label: 'Tápszerek', icon: 'pi pi-fw pi-pencil', to:'/tapszer'},
                            {label: 'Eszközök', icon: 'pi pi-fw pi-filter', to:'/eszkoz'},
                            {label: 'Vizit típusok', icon: 'pi pi-fw pi-directions', to:'/vizittipus'},
                            {label: 'Költség típusok', icon: 'pi pi-fw pi-pencil', to:'/koltseg'},
                            {label: 'Kistérségek', icon: 'pi pi-fw pi-compass', to:'/kisterseg'},
                            {label: 'Települések', icon: 'pi pi-fw pi-compass', to:'/telepules'},
                            ]
                        },
                    ]
                },
            ],
			layoutColors: [
				{name:'Amber Pink', file:'amber', color:'#FFB300'},
				{name:'Blue Amber', file:'blue', color:'#1E88E5'},
				{name:'Blue Grey Green', file:'bluegrey', color:'#607D8B'},
				{name:'Brown Cyan', file:'brown', color:'#795548'},
				{name:'Cyan Amber', file:'cyan', color:'#00BCD4'},
				{name:'Deep Orange Cyan', file:'deeporange', color:'#F4511E'},
				{name:'Deep Purple Orange', file:'deeppurple', color:'#5E35B1'},
				{name:'Green Brown', file:'green', color:'#43A047'},
				{name:'Grey Indigo', file:'grey', color:'#757575'},
				{name:'Indigo Pink', file:'indigo', color:'#3f51b5'},
				{name:'Light Blue Blue Grey', file:'lightblue', color:'#03A9F4'},
				{name:'Light Green Purple', file:'lightgreen', color:'#7CB342'},
				{name:'Lime Blue Grey', file:'lime', color:'#C0CA33'},
				{name:'Orange Indigo', file:'orange', color:'#FB8C00'},
				{name:'Pink Amber', file:'pink', color:'#D81B60'},
				{name:'Purple Pink', file:'purple', color:'#8E24AA'},
				{name:'Teal Red', file:'teal', color:'#009688'},
				{name:'Yellow Teal', file:'yellow', color:'#FBC02D'},
			],
			layoutSpecialColors: [
				{name:'Reflection', file:'reflection', image:'reflection.png'},
				{name:'Moody', file:'moody', image:'moody.png'},
				{name:'Cityscape', file:'cityscape', image:'cityscape.png'},
				{name:'Cloudy', file:'cloudy', image:'cloudy.png'},
				{name:'Storm', file:'storm', image:'storm.png'},
				{name:'Palm', file:'palm', image:'palm.png'},
				{name:'Flatiron', file:'flatiron', image:'flatiron.png'},
				{name:'Panaroma', file:'panaroma', image:'panaroma.png'},
				{name:'Horizon', file:'horizon', image:'horizon.png'},
			],
			themeColors: [
				{ name: 'Blue Amber', file: 'blue', image: 'blue-amber.svg' },
				{ name: 'Blue Grey Green', file: 'bluegrey', image: 'bluegrey-green.svg' },
				{ name: 'Brown Cyan', file: 'brown', image: 'brown-cyan.svg' },
				{ name: 'Cyan Amer', file: 'cyan', image: 'cyan-amber.svg' },
				{ name: 'Deep Orange Cyan', file: 'deeporange', image: 'deeporange-cyan.svg' },
				{ name: 'Deep Purple Orange', file: 'deeppurple', image: 'deeppurple-orange.svg' },
				{ name: 'Green Brown', file: 'green', image: 'green-brown.svg' },
				{ name: 'Grey Indigo', file: 'grey', image: 'grey-indigo.svg' },
				{ name: 'Indigo Pink', file: 'indigo', image: 'indigo-pink.svg' },
				{ name: 'Light Blue Blue Grey', file: 'lightblue', image: 'lightblue-bluegrey.svg' },
				{ name: 'Light Green Purple', file: 'lightgreen', image: 'lightgreen-purple.svg' },
				{ name: 'Lime Blue Grey', file: 'lime', image: 'lime-bluegrey.svg' },
				{ name: 'Orange Indigo', file: 'orange', image: 'orange-indigo.svg' },
				{ name: 'Pink Amber', file: 'pink', image: 'pink-amber.svg' },
				{ name: 'Purple Pink', file: 'purple', image: 'purple-pink.svg' },
				{ name: 'Teal Red', file: 'teal', image: 'teal-red.svg' },
				{ name: 'Yellow Teal', file: 'yellow', image: 'yellow-teal.svg' }
			]
        }
    },
    created() {
        this.fullMenu.forEach((v) => {
            if(v.role == undefined || v.role == this.User.role){
                this.menu.push(v)
            }
        })
        if(window.innerWidth > 1450){
            this.layoutMode = 'static'
        }else{
            this.layoutMode = 'overlay'
        }        
    },
    mounted() {
        this.fullMenu[0].items[0].label = (this.UserName.length > 21) ? this.UserName.substr(0,18) + '...' : this.UserName
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
			if (!this.menuClick && !this.menuButtonClick && this.mobileMenuActive) {
				this.mobileMenuActive = false;
			}

			if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
				this.activeTopbarItem =  null;
				this.topbarMenuActive = false;
			}

			if(!this.menuClick) {
				if(this.isHorizontal() || this.isOverlay()) {
					this.menuActive = false;
					EventBus.emit('reset-active-index');
				}
			}

			this.menuClick = false;
			this.menuButtonClick = false;
			this.topbarMenuClick = false;
			this.topbarMenuButtonClick = false;
        },
		onMenuButtonClick(event) {
			this.menuButtonClick = true;

			if (this.isMobile()) {
				this.mobileMenuActive = !this.mobileMenuActive;
			}

			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarMenuClick = true;
			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		onTopbarMobileMenuButtonClick(event) {
			this.topbarMenuButtonClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			event.preventDefault();
		},
		onSidebarClick() {
			this.menuClick = true;
		},
		onSidebarMouseEnter() {
			if(!this.isMobile()) {
				if (this.sidebarTimeout) {
				clearTimeout(this.sidebarTimeout);
			}
				this.$refs.sidebar.classList.add('layout-sidebar-active');
				this.sidebarActive = true;
			}
		},
		onSidebarMouseLeave() {
			this.sidebarTimeout = setTimeout(() => {
				this.$refs.sidebar.classList.remove('layout-sidebar-active');
			}, 250);
			this.sidebarActive = false;
		},
		onToggleMenuClick() {
			this.layoutMode = (this.layoutMode !== 'static') ? 'static' : 'overlay';
		},
		isOverlay() {
			return this.layoutMode === 'overlay';
		},
		isHorizontal() {
			return this.layoutMode === 'horizontal';
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		onMenuItemClick(event) {
			if(!event.item.items) {
				EventBus.emit('reset-active-index');
			}
			if(!event.item.items && this.isHorizontal()) {
				this.menuActive = false;
			}
			if(!event.item.items && this.isMobile()) {
				this.mobileMenuActive = false;
			}
		},
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onMenuColorChange(menuColor) {
			this.darkMenu = menuColor;
		},
		changeTheme(theme) {
			this.$emit('theme-change', theme);
		},
		changeLayout(layout) {
			this.$emit('layout-change', layout);
		},
		isMobile() {
			return window.innerWidth <= 1024;
		},
		changeThemeStyle(compactMode) {
			this.$emit('change-theme-style', compactMode);
		},
		changeOrientation(value) {
			this.isRTL = value;
		}
    },
    computed: {
        ...mapGetters({
            User:       'user/User',
            UserName:   'user/UserName',
        }),
        containerClass() {
            return ['layout-wrapper', {
				'layout-wrapper-static': this.layoutMode === 'static',
				'layout-menu-horizontal': this.layoutMode === 'horizontal',
				'layout-wrapper-active': this.mobileMenuActive,
				'p-input-filled': this.$appState.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false,
				'layout-rtl': this.isRTL
			}];
        },
		sidebarClass() {
			return ['layout-sidebar', {'layout-sidebar-dark': this.darkMenu}];
		},
        node_name(){
            return process.env.VUE_APP_NODE_NAME
        }
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        //'AppConfig': AppConfig,
        'AppFooter': AppFooter,
        //'AppBreadcrumb': AppBreadcrumb
    }
}
</script>

<style lang="scss">
@import './App.scss';

.l-teszt-layout-main {
    background-color: #7fff00;
}

@media only screen and (max-width: 1024px) {
    .layout-content {
        margin-top: 16px !important;
    }
}

</style>

