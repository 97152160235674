<template>
	<div class="layout-footer clearfix">
		<span class="footer-text-right">
			<span>Minden jog fenntartva</span>
		</span>
		<span class="footer-text-left">
			<span>{{ node_name + ' V' + app_version }}</span>
		</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
        computed:{
            node_name(){
                return process.env.VUE_APP_NODE_NAME
            },
            app_version(){
                return process.env.VUE_APP_VERSION
            }
        }    
	}
</script>

<style scoped>

</style>
